<template>
  <div class="p-4 bg-white rounded-lg">
    <iframe
      src="https://backend.iqfulfillment.com/iq_ai"
      frameborder="0"
      ref="iframe"
      class="custom-content-height"
      style="width: 100% !important"
      width="100%"
      height="100%"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: "AI-DescriptionGenerator",
};
</script>
