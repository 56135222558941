<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add Call</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <v-card-text class="pt-6">
          <!--        entities form-->

          <form id="createForm" class="pa-3 pb-4">
            <v-select
              v-model="formData.call_status"
              label="Call status"
              item-text="text"
              item-value="index"
              :items="call_statuses"
              clearable
              required
              :error-messages="call_statusErrors"
              @input="$v.formData.call_status.$touch()"
              @blur="$v.formData.call_status.$touch()"
            ></v-select>
            <v-datetime-picker
              ref="datetime"
              v-if="isDateFieldVisible"
              label="Appointed to Date/Time"
              v-model="formData.date_time"
              prepend-icon="mdi-calendar"
              outlined
              :datePickerProps="{
                min: tommorowDate,
              }"
            >
            </v-datetime-picker>
            <v-text-field
              v-model="formData.comment"
              label="Comment"
              clearable
            ></v-text-field>
            <v-checkbox
              v-model="formData.order_validation"
              :true-value="true"
              :false-value="false"
              color="indigo"
              label="Order Validation"
            ></v-checkbox>
            <v-main class="text-center mt-4">
              <v-btn class="mr-4" color="primary" @click="submitCreateForm">
                Create
              </v-btn>
              <v-btn @click="resetCreateForm"> Clear </v-btn>
            </v-main>
          </form>
        </v-card-text>
        <v-card class="main">
          <v-card-title class="primary lighten-1">
            <v-toolbar-title class="white--text h3"
              >Order detail</v-toolbar-title
            >
          </v-card-title>
          <v-card-text>
            <div v-for="(item, i) in other" :key="i">
              <pop-up-selector :item="item"></pop-up-selector>
            </div>
          </v-card-text>
          <div style="flex: 1 1 auto"></div>
        </v-card>

        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import PopUpSelector from "@/own/components/fulfillment/orders/popup/PopUpSelector.vue";
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      call_status: { required },
    },
  },
  name: "OrderCall",
  props: ["pageLoader", "call_statuses", "refresher"],
  components: { PopUpSelector },
  data: () => ({
    dialog: false,
    dateFieldVisible: false,
    formData: {
      order_id: null,
      date_time: null,
      call_status: null,
      comment: null,
      order_validation: false,
    },
    item: null,
    other: null,
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    setData(itemData, otherData) {
      this.item = itemData;
      this.other = otherData;
    },
    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      } else {
        this.formData.order_id = this.item.id.id;
      }
      this.dialog = !this.dialog;
    },
    validateData() {
      if (this.formData.date_time) {
        return true;
      } else {
        if (
          this.formData.call_status === 700 ||
          this.formData.call_status === 800
        ) {
          Swal.fire({
            title: "Warning",
            text: `Date is required!`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
          return false;
        } else {
          return true;
        }
      }
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.validateData()) {
          this.pageLoader(true);
          let data = this.formData;
          if (this.formData.date_time) {
            data.date_time = this.dateFormatter(this.formData.date_time);
          }

          ApiService.post("/customer_service/call_sheets/add_call", data)
            .then(() => {
              Swal.fire({
                title: "Created",
                text: `Order call added!`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.toggleModal();
              this.resetCreateForm();
              this.refresher();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        } else {
          return null;
        }
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        order_id: null,
        call_status: null,
        date_time: null,
        comment: null,
      };
      this.item = null;
      this.other = null;
      // this.$refs.datetime.clearHandler();
    },
    dateFormatter(date) {
      if (!Object.prototype.toString.call(date) === "[object Date]") {
        return date;
      }
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
  },
  computed: {
    tommorowDate() {
      let cdate = new Date();
      cdate.setDate(cdate.getDate() + 1);
      return cdate.toISOString().split("T")[0];
    },
    isDateFieldVisible: function () {
      return this.dateFieldVisible;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    //700  800
    call_statusErrors() {
      return this.handleFormValidation("call_status", this);
    },
  },
  watch: {
    formData: {
      handler(n) {
        this.dateFieldVisible = n.call_status === 700 || n.call_status === 800;
      },
      deep: true,
    },
  },
};
</script>
