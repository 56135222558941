<template>
  <div class="pt-0 pb-4 px-4">
    <div
      v-if="title && item.length > 0"
      class="text-dark-65 font-size-h2 display4-md font-weight-bolder pb-1"
    >
      {{ title }}
    </div>
    <div class="row">
      <div
        v-for="(serverData, i) in item"
        :key="i"
        class="col-12 col-sm-6 col-md-4 col-lg-4 px-1"
      >
        <v-card
          elevation="0"
          hover
          class="d-block ma-0 pa-2 border-circular poppins"
          :class="getRandomColors(i)"
        >
          <div class="d-flex justify-center align-center">
            <div>
              <v-card-text>
                <!-- <div class="h4 pl-1">{{ serverData.dateLabel }}</div> -->
                <div style="font-size: 24px" class="pl-1 text-white">
                  {{ serverData.value }}
                </div>
              </v-card-text>
              <v-card-text class="h6 text-white">
                {{ serverData.name }}
              </v-card-text>
            </div>
            <v-spacer></v-spacer>
            <div class="pr-4">
              <div>
                <img
                  v-if="serverData.image"
                  class=""
                  :src="serverData.image"
                  width="76px"
                  height="76px"
                  :alt="serverData.name"
                />
                <div
                  v-else
                  class="name-letter rounded-circle"
                  :class="getRandomColors2(i)"
                >
                  {{ serverData.name[0] }}
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Statistics",
  props: {
    item: { required: true },
    title: { required: false, default: null },
  },
  data: () => ({
    bgColors: [
      "bg-info text-info",
      "bg-primary text-primary",
      "bg-danger text-danger",
      "bg-warning text-warning",
      "bg-success text-success",
    ],
    bgColors2: [
      "bg-light-info text-info",
      "bg-light-primary text-primary",
      "bg-light-danger text-danger",
      "bg-light-warning text-warning",
      "bg-light-success text-success",
    ],
  }),
  methods: {
    getRandomColors: function (i) {
      // return this.bgColors[Math.floor(Math.random() * this.bgColors.length)];
      return this.bgColors[i];
    },
    getRandomColors2: function (i) {
      // return this.bgColors[Math.floor(Math.random() * this.bgColors.length)];
      return this.bgColors2[i];
    },
  },
};
</script>

<style scoped>
.border-circular {
  border-radius: 12px;
  /* background-image: linear-gradient(to right, red , yellow); */
}

.name-letter {
  width: 76px;
  height: 76px;
  color: white;
  font-size: 44px;
  text-align: center;
  font-weight: bold;
}
</style>
